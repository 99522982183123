// import store from "../../store";
import axios from "axios";
import VueAxios from "vue-axios";

var current_app;

function setupAxiosHandler(app) {
  current_app = app;
  current_app.use(VueAxios, axios);
  current_app.axios.defaults.xsrfCookieName = "csrftoken";
  current_app.axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
  console.log(hostname)
  current_app.axios.interceptors.request.use(function (config) {
    // if (store.state.authStore.authToken) {
    //   config.headers.Authorization = "Token " + store.state.authStore.authToken;
    // }
    // if (store.state.authStore.dealerToken) {
    //   config.headers["current-dealer"] = store.state.authStore.dealerToken;
    // }

    return config;
  });
}

function axiosInstance() {
  return current_app.axios;
}

const hostname = process.env.VUE_APP_HOSTNAME;

const apiConstants = {
  api_hostname: hostname + "api/",
  hostname: hostname
};

const endpoints = {
  login: apiConstants.api_hostname + "login/",

};

export { setupAxiosHandler, axiosInstance, apiConstants, endpoints };
