<template>
  <router-view />
</template>

<style lang="scss">
@import "~@/fonts/sf-pro/stylesheet.scss";

* {
	padding: 0px;
	margin: 0px;
}
</style>
